import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {BriefcaseIcon, MortarBoardIcon, AlertIcon, PlusSmallIcon} from 'react-octicons'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';

import ProfilePicture from "profile-picture"
import "profile-picture/build/ProfilePicture.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import swal from 'sweetalert';

import './profile.css';

export default class Profile extends Component<Props> {
  state = {
    showAddTimeline: false,
    showEditProfile: false,
    profile: {
      references: [],
      experience: []
    },
    showApplication: false,
    application: {
      data: {
        application: ""
      }
    },
    yourProfile: false,
    editProfilePicture: false,

    showRejectionForm: false,
    letter: ""
  }

  constructor(props) {
    super(props);

    this.getProfile = this.getProfile.bind(this);
    this.toggleTimelineAdd = this.toggleTimelineAdd.bind(this);
    this.toggleEditProfile = this.toggleEditProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.sendRejection = this.sendRejection.bind(this);
    this.toggleFavorite = this.toggleFavorite.bind(this);
  }

  componentDidMount() {
    this.getProfile();
    console.log("Load");

    if(typeof this.props.match.params.application !== 'undefined') {
      this.setState({showApplication: true});
      this.getApplication();
    }
  }

  getProfile() {
    axios.get("https://l16.vixo.no/api/public/get/Profile/user/"+this.props.match.params.url).then(res => {
      this.setState({profile: res.data});

      let viewerData = JSON.parse(window.localStorage.getItem("userData"));
      if(this.state.profile.id == viewerData.id) {
        this.setState({yourProfile: true});
      }
    });
  }

  getApplication() {
    axios.get("https://l16.vixo.no/api/public/v2/get/application/"+this.props.match.params.application).then(res => {
      this.setState({application: res.data});
    });
  }

  toggleTimelineAdd() {
    console.log(this.state.showAddTimeline);
    if(this.state.showAddTimeline == true) {
      this.setState({showAddTimeline: false});
    } else {
      this.setState({showAddTimeline: true});
    }
  }

  toggleEditProfile() {
    console.log(this.state.showEditProfile);
    if(this.state.showEditProfile == true) {
      this.setState({showEditProfile: false});
    } else {
      this.setState({showEditProfile: true});

      let userData = JSON.parse(localStorage.getItem("userData"));

      this.setState({firstname: this.state.profile.firstname});
      this.setState({surname: this.state.profile.surname});
      this.setState({biography: this.state.profile.biography});
    }
  }

  updateProfile() {
    let data = new FormData();
    data.append("firstname", this.state.firstname);
    data.append("surname", this.state.surname);
    data.append("biography", this.state.biography);

    axios.post('https://l16.vixo.no/api/public/update/user', data).then(res => {
      if(res.data.success == "true") {
        this.getProfile();
        this.toggleEditProfile();
      }
    });
  }

  sendRejection() {
    let data = new FormData();
    data.append("application", this.props.match.params.application);
    data.append("message", this.state.letter);

    axios.post('https://l16.vixo.no/api/public/v2/application/decline', data).then(res => {
      if(res.data.success == true) {
        swal("Søknaden er nå avslått!", "Søknaden er nå markert som avslått, og melding har blitt sendt til søkeren!", "success");
        this.props.history.push("/recruiter/positions");
      } else {
        swal("oida!", "Vi kunne ikke avslå søknaden akuratt nå, prøv igjen senere!", "error");
      }
    });
  }

  toggleFavorite() {
    let data = new FormData();

    data.append("application", this.props.match.params.application);

    if(this.state.application.data.favorite == true) {
      data.append("favorite", 0);
    } else {
      data.append("favorite", 1);
    }

    axios.post("https://l16.vixo.no/api/public/set/favorite/application", data).then(res => {
      this.getApplication();
    });
  }

  render() {
    const autocompletionRequest = {
      componentRestrictions: {
        country: "NO"
      }
    }
    return(
      <div>

                <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
                  <Card.Body>
                    <div className={`applicationLetter ${this.state.showApplication == false ? 'hidden':''}`}>
                      <div className={`applicationLetter ${this.state.showApplication == false ? 'hidden':''}`} style={{float: "right"}}>
                        <Button className={`${this.state.application.data.favorite == false ? '':'hidden'}`} variant="primary" onClick={this.toggleFavorite} style={{marginRight: "10px"}}>Sett som favoritt</Button>
                        <Button className={`${this.state.application.data.favorite == false ? 'hidden':''}`} variant="danger" onClick={this.toggleFavorite} style={{marginRight: "10px"}}>Fjern som favoritt</Button>

                        <Button variant="danger" onClick={(e) => {
                          this.setState({showRejectionForm: true});
                        }}>Avslå søknad</Button>
                      </div>

                      <br style={{clear:'both'}} />
                      <br style={{clear:'both'}} />
                    </div>

                    <div className="positionWrapper">
                      <div className="company">
                        <div style={{float: "right"}} onClick={this.toggleEditProfile}>
                          <FontAwesomeIcon icon={faCog} />
                        </div>
                        <div>
                          <center>
                            <img width="150px" style={{borderRadius: "75px"}} onClick={(e) => {this.setState({editProfilePicture: true})}} src={`https://l16.vixo.no/api/public/get/image/${this.state.profile.profilepicture}`} />
                            <div className={`${this.state.yourProfile == false ? '' : 'hidden'}`}>
                              <br />
                              <Button variant="primary" onClick={(e) => {this.props.history.push("/messages/"+this.state.profile.id)}} style={{marginTop: "20px"}}>Send melding</Button>
                            </div>

                            <h3 style={{marginTop: "20px"}}>{this.state.profile.firstname} {this.state.profile.surname}</h3>
                          </center>
                          <p>{this.state.profile.biography}</p>
                        </div>

                      </div>
                      <div className="position">
                        <div className={`profile ${this.state.showEditProfile == true ? '':'hidden'}`}>
                          <h3>Informasjon</h3>
                          <div className="half">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Fornavn</Form.Label>
                              <Form.Control type="text" placeholder="Olas bakeri" value={this.state.firstname} onChange={(e) => {this.setState({firstname: e.target.value})}} />
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Etternavn</Form.Label>
                              <Form.Control type="text" placeholder="Olas bakeri" value={this.state.surname} onChange={(e) => {this.setState({surname: e.target.value})}} />
                            </Form.Group>
                          </div>

                          <div className="half">
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Adresse</Form.Label>
                              <GooglePlacesAutocomplete onSelect={console.log} autocomplete="off" placeholder="Norgesveien 4, 1234 Oslo" autocompletionRequest={autocompletionRequest} />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Kjønn</Form.Label>
                              <Form.Control as="select">
                                <option value="male">Mann</option>
                                <option value="female">Kvinne</option>
                                <option value="other">Annet</option>
                                <option value="">Ønsker ikke å spesifisere</option>
                              </Form.Control>
                            </Form.Group>
                          </div>

                          <div className="half">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Epost</Form.Label>
                            <Form.Control type="text" placeholder="ola@example.org" />
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control type="text" placeholder="12345678" />
                          </Form.Group>
                          </div>

                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Biografi</Form.Label>
                            <Form.Control as="textarea" rows="3" placeholder="Beskrivelse" value={this.state.biography} onChange={(e) => {this.setState({biography: e.target.value})}} />
                          </Form.Group>

                          <Button variant="primary" onClick={this.updateProfile}>Lagre</Button>

                          <h3 style={{marginTop: "20px"}}>Introduksjons video</h3>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Biografi</Form.Label>
                            <div className="custom-file">
                              <input type="file" className="custom-file-input" id="customFileLang" lang="no" />
                              <label className="custom-file-label" for="customFileLang">Velg</label>
                            </div>
                          </Form.Group>


                          <Button variant="primary" onClick={this.updateProfile}>Last opp</Button>

                        </div>
                        <div className={`profile ${this.state.showEditProfile == true ? 'hidden':''}`}>
                          <div className={`applicationLetter ${this.state.showApplication == false ? 'hidden':''}`}>
                            <div className="titleArea" >
                              <h3 className="title">Søknadsbrev</h3>
                            </div>
                            <p dangerouslySetInnerHTML={{__html: this.state.application.data.application}}></p>
                          </div>

                          <div className="titleArea">
                            <h3 className="title">Tidslinje</h3>

                            <h3 className="actions" ><PlusSmallIcon height="40px" onClick={this.toggleTimelineAdd} /></h3>
                          </div>

                          <div className={`addTimeline ${ this.state.showAddTimeline == true ? '' : 'hidden' }`}>


                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Tittel / Navn</Form.Label>
                              <Form.Control type="text" placeholder="Olas bakeri" />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Beskrivelse</Form.Label>
                              <Form.Control as="textarea" rows="3" placeholder="Beskrivelse" />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Type</Form.Label>
                              <Form.Control as="select">
                                <option>Jobb</option>
                                <option>Utdanning</option>
                              </Form.Control>
                            </Form.Group>

                            <Button variant="success" size="lg" block>Legg til!</Button>

                          </div>

                          <VerticalTimeline layout="1-column">

                            {this.state.profile.experience.map(function(item, i){
                              let icon = (<BriefcaseIcon fill="white" />);
                              let color = "";

                              switch(item.type) {
                                case "work":
                                  icon = (<BriefcaseIcon fill="white" />);
                                  color = "rgb(33, 150, 243)";
                                  break;
                                case "education":
                                  icon = (<MortarBoardIcon fill="white" />);
                                  color = "#449e43";
                                  break;
                                case "void":
                                  icon = (<AlertIcon fill="white" />);
                                  color = "#d26a5c";
                                  break;
                                default:
                                  // code block
                              }

                              return (
                                <VerticalTimelineElement
                                  className="vertical-timeline-element--work"
                                  contentStyle={{ background: '#f0f0f0', color: 'black' }}
                                  contentArrowStyle={{ borderRight: '7px solid  #f0f0f0' }}
                                  date=""
                                  iconStyle={{ background: color, color: 'white' }}
                                  icon={icon}
                                >
                                  <h4 className="vertical-timeline-element-title">{item.title}</h4>
                                  <h6 className="vertical-timeline-element-subtitle">{item.start} -  {item.finish}</h6>
                                  <p>
                                    {item.text}
                                  </p>
                                </VerticalTimelineElement>

                              )
                            })}
                          </VerticalTimeline>

                          <div className="references">
                            <h3>Referanser</h3>
                          </div>

                          {this.state.profile.references.map(function(item, i){
                            return (
                              <div style={{paddingLeft: "10px"}}>
                                <h4>{item.company}</h4>
                                <div style={{paddingLeft: "10px"}}>
                                  <p>
                                    <b>Ansvar:</b><br />
                                    {item.responsibilities}
                                  </p>
                                  <p>
                                    <b>Utførelse:</b><br />
                                    {item.execution}
                                  </p>
                                  <p>
                                    <b>Samarbeid:</b><br />
                                    {item.coop}
                                  </p>
                                  <p>
                                    <b>Positive egenskaper:</b><br />
                                    {item.positive}
                                  </p>
                                </div>
                                <p>
                                  <i>
                                    <span className={item.leader == "yes" ? '':'hidden'}>Dette er en lederstilling.</span>
                                    <span className={item.employeagain == "yes" ? '':'hidden'}> Arbeidsgiver hadde ansatt denne arbeidstakeren igjen.</span>
                                  </i>
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>


                <Modal show={this.state.editProfilePicture} onHide={(e) => {this.setState({editProfilePicture: false})}}>
                  <Modal.Header closeButton>
                    <Modal.Title>Velg profilbilde</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ProfilePicture
                      ref={this.profilePictureRef}
                      useHelper={true}
                      debug={true}
                    />
                  </Modal.Body>
                </Modal>

                <Modal show={this.state.showRejectionForm} onHide={(e) => {this.setState({showRejectionForm: false})}}>
                  <Modal.Header closeButton>
                    <Modal.Title>Avslå søknad</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Skriv en melding til søker:<br />
                    <CKEditor
                      editor={ ClassicEditor }
                      data=""
                      onInit={ editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          //console.log( { event, editor, data } );
                          this.setState({letter: data})
                      } }
                    />
                    <div>
                      <Button variant="danger" style={{float: "right", marginTop: "10px"}} onClick={this.sendRejection}>Send avslag</Button>
                    </div>
                  </Modal.Body>
                </Modal>
      </div>
    );
  }
}
