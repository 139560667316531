import React, { Component } from 'react';
import { Card, Button, ListGroup } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
//import "./home.css";

import axios from "axios";

export default class RecruiterPositions extends Component<Props> {
  state = {
    isLoggedIn: false,
    results: []
  }

  constructor(props) {
    super(props);
    this.openPosition = this.openPosition.bind(this);
    this.createposition = this.createposition.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.search();
  }

  openPosition(id, job) {
    this.props.history.push({
      pathname: "/recruiter/position/"+id,
      job: job
    })
  }

  search() {

    axios.get("https://l16.vixo.no/api/public/get/recruiter/posts").then(res => {
      this.setState({results: res.data});
    });
  }

  createposition() {
    this.props.history.push("/recruiter/create/position");
  }

  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>

          <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
            <Card.Body>
              <div className="topContainer">
                <div className="left">
                  <Card.Title className={this.state.results.length == 0 ? 'hidden':''}>Utlysninger</Card.Title>
                  <Card.Subtitle className={`mb-2 text-muted ${this.state.results.length == 0 ? 'hidden':''}`}>Du har {this.state.results.length} utlysninger!</Card.Subtitle>
                </div>

                <div className="right" style={{textAlign: "right"}}>
                  <Button variant="primary" onClick={this.createposition}>Opprett utlysning</Button>
                </div>
              </div>
              <Card.Text>
                <ListGroup>
                       {this.state.results.map((item) => (
                            <ListGroup.Item className="listGroupSegments" onClick={(e) => {this.openPosition(item.id, item)}}>
                              <div className="lgLogo"></div>
                              <div className="lgContent">
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                              </div>

                              <div className={`lgAction ${item.isAd == false ? '' : 'hidden'}`}>
                                Reklame
                              </div>
                            </ListGroup.Item>
                          )
                        )}

                </ListGroup>

                <div className={this.state.results.length == 0 ? '':'hidden'} style={{"textAlign": "center"}}>
                  <h3>Oida! Her var det ikke mange utlysninger, prøv igjen senere!</h3>
                  <p>Kanskje du ønsker å se over profilen din, en ryddig og oppdatert profil er viktig!</p>
                  <p style={{"paddingLeft": "20%", "paddingRight": "20%"}}>Du kan motta meldinger selvom det ikke er noen aktive utlysninger i portalen, hodejegere kan søke etter kompetanse på stillinger som ikke ligger offentlig.</p>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
