import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
//import "./home.css";

import axios from "axios";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "./createposition.css";

export default class RecruiterCreatePosition extends Component<Props> {
  state = {
    isLoggedIn: false,
    results: [],

    stage: "company",

    showLogoPicker: false,

    showCompanyPicker: false,
    companies: [],
    company: {
      navn: ""
    },
    media: [],
    logo: {
      id: 0,
      name: ""
    }
  }

  constructor(props) {
    super(props);
    this.openPosition = this.openPosition.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.SearchCompany = this.SearchCompany.bind(this);
    this.nextStage = this.nextStage.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.loadMedia();
  }

  openPosition(id, job) {
    this.props.history.push({
      pathname: "/recruiter/position/"+id,
      job: job
    })
  }

  handleClose() {
    this.setState({showCompanyPicker: false});
  }

  handleCloseLogo() {
    this.setState({showLogoPicker: false});
  }

  SearchCompany(value) {
    console.log(value);
    axios.get("https://data.brreg.no/enhetsregisteret/api/enheter?navn=" + value).then(res => {
      if(typeof res.data._embedded !== 'undefined') {
        this.setState({companies: res.data._embedded.enheter});
      }
    });
  }

  loadMedia() {
    axios.get("https://l16.vixo.no/api/public/get/media").then(res => {
      this.setState({media: res.data.images});
    });
  }

  nextStage() {
    if(this.state.stage == "company") {
      this.setState({stage: "position"});
    }
  }

  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>

          <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
            <Card.Body>
              <div className="topContainer">
                <div className="left">
                  <Card.Title>Opprett utlysning</Card.Title>
                </div>

                <div className="right" style={{textAlign: "right"}}>
                  <Button variant="primary" onClick={this.nextStage}>{this.state.stage == "company" ? "Neste steg" : "Opprett"}</Button>
                </div>
              </div>
              <Card.Text>

                <Form className={this.state.stage == "company" ? "":"hidden"}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Firma</Form.Label><br />
                    {this.state.company.navn}<br />
                    <Button variant="primary" onClick={(e) => { this.setState({showCompanyPicker: true}) }}>Velg bedrift</Button>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Logo</Form.Label><br />
                      <div className={`galleryItem ${this.state.logo.name != "" ? "": "hidden"} `}>
                        <img src={`https://l16.vixo.no/api/public/get/image/${this.state.logo.name}`} />
                      </div>
                    <Button variant="primary" onClick={(e) => { this.setState({showLogoPicker: true}) }}>Velg logo</Button>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Ingress</Form.Label>
                    <Form.Control type="text" placeholder="En rask beskrivelse av bedriften." />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Adressen til arbeidsplassen</Form.Label>
                    <Form.Control type="text" placeholder="Norgesveien 4, oslo" />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Skriv litt om bedriften og hvordan det er å jobbe der.</Form.Label>
                      <CKEditor
                        editor={ ClassicEditor }
                        data=""
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            //console.log( { event, editor, data } );
                            this.setState({application: data})
                        } }
                    />
                  </Form.Group>
                </Form>




                <Form className={this.state.stage == "position" ? "":"hidden"}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Stillingstittel</Form.Label><br />
                    <Form.Control type="text" placeholder="Olas bakeri" />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Stillingsprosent</Form.Label><br />
                    <Form.Control type="text" placeholder="Olas bakeri" />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Lønn (Valgfri)</Form.Label><br />
                    <Form.Control type="text" placeholder="350 000" />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Søknadsfrist</Form.Label><br />
                    <Form.Control type="text" placeholder="F.eks Snarest / Vi vurderer løpende søknader" />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Skriv litt om stillingen, hva kreves og hva ønskes av arbeidstakeren?</Form.Label>
                      <CKEditor
                        editor={ ClassicEditor }
                        data=""
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            //console.log( { event, editor, data } );
                            this.setState({application: data})
                        } }
                    />
                  </Form.Group>
                </Form>


                <div className="topContainer">

                  <div className="right" style={{textAlign: "right"}}>
                    <Button variant="primary" onClick={this.nextStage}>{this.state.stage == "company" ? "Neste steg" : "Opprett"}</Button>
                  </div>
                </div>


              </Card.Text>
            </Card.Body>
          </Card>



          <Modal show={this.state.showCompanyPicker} onHide={this.handleCloseLogo}>
            <Modal.Header closeButton>
              <Modal.Title>Velg bedrift</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control type="text" placeholder="Olas bakeri" onChange={(e) => {
                      this.SearchCompany(e.target.value);
                    }} />
                </Form.Group>

                <ListGroup>
                       {this.state.companies.map((item) => (
                            <ListGroup.Item onClick={(e) => {this.setState({company: item}); this.setState({showCompanyPicker: false})}}>
                              <div className="lgContent">
                                <h4>{item.navn}</h4>
                                <p>{item.organisasjonsnummer}</p>
                              </div>
                            </ListGroup.Item>
                          )
                        )}
                </ListGroup>
              </Form>
            </Modal.Body>
          </Modal>


          <Modal show={this.state.showLogoPicker} onHide={this.handleCloseLogo}>
            <Modal.Header closeButton>
              <Modal.Title>Velg logo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.media.map((item) => (
                <div className="galleryItem gFloat" onClick={(e) => {this.setState({logo: item}); this.setState({showLogoPicker: false})}}>
                  <img src={`https://l16.vixo.no/api/public/get/image/${item.name}`} />
                </div>
                 )
               )}
            </Modal.Body>
          </Modal>





        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
