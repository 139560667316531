import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './position.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import swal from 'sweetalert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

export default class Position extends Component<Props> {

  state = {
    showApplication: false,
    job: {
      alreadyApplied: false
    },
    application: ""
  }

  constructor(props) {
    super(props);

    this.openApplicationForm = this.openApplicationForm.bind(this);
    this.sendApplication = this.sendApplication.bind(this);
    this.onChange = editorState => this.setState({editorState});
  }

  componentDidMount() {
    if(typeof this.props.location.job !== 'undefined') {
      this.setState({job: this.props.location.job});
    }

    this.loadPosition();
  }

  stateChange(e){
    console.log(e);
  }

  openApplicationForm() {
    if(this.state.showApplication == true) {
      this.setState({showApplication: false});
    } else {
      this.setState({showApplication: true});
    }
  }

  sendApplication() {
    let data = new FormData();
    data.append("text", this.state.application);
    data.append("postid", this.props.match.params.id);
    axios.post("https://l16.vixo.no/api/public/v2/application/send", data).then(res => {
      if(res.data.success == true) {
        this.props.history.push("/");
        swal("Søknaden er sendt!", "Din søknad er nå sendt, lykke til!", "success")
      } else {
        swal('Oida!', 'Noe uventet har oppstått, vennligst prøv igjen senere!', 'error');
      }
    }).catch(e => {
      swal('Oida!', 'Noe uventet har oppstått, vennligst prøv igjen senere!', 'error');
    });
  }

  handleEditorChange(content, editor) {
     console.log('Content was updated:', content);
   }

   loadPosition() {
     axios.get("https://l16.vixo.no/api/public/v2/get/job/" + this.props.match.params.id).then(res => {
       this.setState({job: res.data.data});
     });
   }

  render() {
    return(
      <div>

                <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
                  <Card.Body>
                    <div className="positionWrapper">
                      <div className="company">
                        <center>
                          <img width="150px" src={`https://l16.vixo.no/api/public/get/image/${this.state.job.logo}`} /><br />
                            <Button className={`${this.state.job.alreadyApplied == true ? 'hidden' : ''}`} variant="primary" onClick={this.openApplicationForm} style={{marginTop: "20px"}}>Send søknad</Button>
                            <Button className={`${this.state.job.alreadyApplied == true ? '' : 'hidden'}`} variant="primary" style={{marginTop: "20px"}} disabled>Du har allerede søkt</Button>
                          <h3 style={{marginTop: "20px"}}>{this.state.job.company}</h3>
                          <h5>{this.state.job.position}</h5>
                        </center>
                        <p>{this.state.job.description}</p>
                      </div>
                      <div className="position">
                        <div className={`${this.state.showApplication == false ? '': 'hidden'}`}>
                          <h3>{this.state.job.title}</h3>
                          <p dangerouslySetInnerHTML={{__html: this.state.job.text}}>
                          </p>
                        </div>

                        <div className={`${this.state.showApplication == false ? 'hidden': ''}`}>
                          <h3>{this.state.job.position} - {this.state.job.company}</h3>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Søknadsbrev</Form.Label>

                              <CKEditor
                                editor={ ClassicEditor }
                                data=""
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    //console.log( { event, editor, data } );
                                    this.setState({application: data})
                                } }
                            />
                          </Form.Group>

                          <Button variant="success" size="lg" onClick={this.sendApplication} block>Send!</Button>

                        </div>

                      </div>
                    </div>
                  </Card.Body>
                </Card>

      </div>
    );
  }
}
