import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './login.css';

export default class Login extends Component<Props> {

  state = {
    email: "",
    password: ""
  }

  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
  }

  componentDidMount() {
  }

  login() {
    let data = new FormData();
    data.append("email", this.state.email);
    data.append("password", this.state.password);

    axios.post('https://l16.vixo.no/api/public/login', data).then(res => {
      let data = res.data;
      if(data.success == true) {
        localStorage.setItem("userData", JSON.stringify(data));
        localStorage.setItem("token", data.token);
        if(data.role == "recruiter") {
          this.props.history.push("/recruiter/positions");
        } else {
          this.props.history.push("/dashboard");
        }
      }
    })
  }

  stateChange(e){
    console.log(e);
  }

  render() {
    return(
      <div className="login-wrapper">
        <Card style={{ width: '100%' }}>
          <Card.Body>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Epost</Form.Label>
                <Form.Control type="email" placeholder="Din epost" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}} />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Passord</Form.Label>
                <Form.Control type="password" placeholder="**********" value={this.state.password} onChange={(e) => {this.setState({password: e.target.value})}} />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={this.login}>
                Logg på!
              </Button>
          </Card.Body>
        </Card>

      </div>
    );
  }
}
