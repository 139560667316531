import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
//import './position.css';

export default class RecruiterPosition extends Component<Props> {

  state = {
    showApplication: false,
    job: {

    },


    applications: [],
    tab: "position"
  }

  constructor(props) {
    super(props);

    this.openApplicationForm = this.openApplicationForm.bind(this);
    this.loadApplications = this.loadApplications.bind(this);
    this.loadPosition = this.loadPosition.bind(this);
  }

  componentDidMount() {
    if(typeof this.props.location.job !== 'undefined') {
      this.setState({job: this.props.location.job});
    }

    this.loadApplications();
    this.loadPosition();
  }

  loadApplications() {
    axios.get("https://l16.vixo.no/api/public/get/recruiter/applications/"+this.props.match.params.id).then(res=>{
      this.setState({applications: res.data})
    });
  }

  loadPosition() {
    axios.get("https://l16.vixo.no/api/public/v2/get/job/" + this.props.match.params.id).then(res => {
      this.setState({job: res.data.data});
    });
  }

  stateChange(e){
    console.log(e);
  }

  openApplicationForm() {
    if(this.state.showApplication == true) {
      this.setState({showApplication: false});
    } else {
      this.setState({showApplication: true});
    }
  }

  render() {
    const opts = {
      playerVars: {
        modestbranding: 1,
        rel: 0
      }
    };

    return(

      <div>

                <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
                  <Card.Body>
                    <div className="positionWrapper">
                      <div className="company">
                        <center>
                          <img width="150px" src={`https://l16.vixo.no/api/public/get/image/${this.state.job.logo}`} /><br />
                          <h3 style={{marginTop: "20px"}}>{this.state.job.company}</h3>
                          <h5>{this.state.job.position}</h5>
                        </center>
                        <p>{this.state.job.description}</p>
                      </div>
                      <div className="position">
                        <div className={`${this.state.showApplication == false ? '': 'hidden'}`}>
                          <h3>{this.state.job.title}</h3>

                          <Tabs
                            id="controlled-tab-example"
                            activeKey={this.state.tab}
                            onSelect={(k) => this.setState({tab: k})}
                          >
                            <Tab eventKey="position" title="Utlysning">
                              <br />
                              <p dangerouslySetInnerHTML={{__html: this.state.job.text}}></p>
                            </Tab>

                            <Tab eventKey="applications" title={`Applikasjoner (${this.state.applications.length})`}>
                            <br />
                            <ListGroup>

                                   {this.state.applications.map((item) => (
                                        <ListGroup.Item className="listGroupSegments" onClick={(e) => {this.props.history.push("/profile/"+item.user.url+"/application/"+item.id)}}>
                                          <div className="lgLogo">
                                            <img style={{height: "70px", width: "70px", borderRadius: "40px", marginRight: "50px", marginLeft: "10px"}} src={`https://l16.vixo.no/api/public/get/image/${item.user.profilepicture}`} />
                                          </div>
                                          <div className="lgContent">
                                            <h4>{item.user.name}</h4>
                                            <p>&nbsp;</p>
                                          </div>

                                          <div className={`lgAction ${item.favorite == "1" ? '' : 'hidden'}`}>
                                            Favoritt
                                          </div>
                                        </ListGroup.Item>
                                      )
                                    )}

                            </ListGroup>

                            </Tab>

                          </Tabs>

                        </div>

                        <div className={`${this.state.showApplication == false ? 'hidden': ''}`}>
                          <h3>{this.state.job.position} hos Orkla ASA</h3>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Søknadsbrev</Form.Label>
                              <Form.Control as="textarea" rows="3" placeholder="Beskrivelse" onChange={(e)=>{this.setState({application: e.target.value})}} />
                            </Form.Group>

                            <Button variant="success" size="lg" onClick={this.sendApplication} block>Send!</Button>

                        </div>

                      </div>
                    </div>
                  </Card.Body>
                </Card>

      </div>
    );
  }
}
