import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './messages.css';

import swal from 'sweetalert';
import ScrollToBottom from 'react-scroll-to-bottom';

export default class Messages extends Component<Props> {

  state = {
    messageList: [],
    messages: {
      lastId: "",
      messages: [],
      other: {
        fullname: ""
      }
    },
    message: ""
  }

  constructor(props) {
    super(props);

    this.openChat = this.openChat.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.loadOlder = this.loadOlder.bind(this);
  }

  componentDidMount() {
    this.getMessageList();
    this.loadChat();
  }

  stateChange(e){
    console.log(e);
  }

  getMessageList() {
    axios.get("https://l16.vixo.no/api/public/get/messagelist").then(res=>{
      if(res.data.success == true) {
        this.setState({messageList: res.data.list});
      } else {
        swal("Oida!", "Vi kunne ikke laste inn dataen korrekt, prøv igjen senere.", "error");
      }
    });
  }

  loadChat() {
    axios.get("https://l16.vixo.no/api/public/get/messages/"+this.props.match.params.id).then(res => {
      this.setState({messages: res.data});
    });
  }

  openChat(id) {
    this.props.history.push("/messages/"+id);
  }

  sendMessage() {
    let data = new FormData();
    data.append("to", this.props.match.params.id);
    data.append("message", this.state.message);

    axios.post("https://l16.vixo.no/api/public/post/message", data).then(res => {
      this.setState({message: ""});
      this.loadChat();
    });
  }

  loadOlder() {
    axios.get("https://l16.vixo.no/api/public/get/messages/"+this.props.match.params.id+"/" + this.state.messages.lastId).then(res => {
      res.data.messages = res.data.messages.concat(this.state.messages.messages);

      this.setState({messages: res.data});
    });
  }

  render() {
    return(
      <div>

        <div className="messagesWrapper">


            <ListGroup className="list">

                   {this.state.messageList.map((item) => (
                        <ListGroup.Item className="chatGroupSegments" onClick={(e) => {this.openChat(item.id)}}>
                          <div className="chatLogo">
                            <img style={{height: "30px", width: "30px", borderRadius: "15px", marginRight: "50px", marginLeft: "10px"}} src={`https://l16.vixo.no/api/public/get/image/${item.profilepicture}`} />
                          </div>
                          <div className="chatContent">
                            <h5>{item.name}</h5>
                            <p>---</p>
                          </div>
                        </ListGroup.Item>
                      )
                    )}

            </ListGroup>

        <Card className="chat">
          <Card.Body>
            <h5>{this.state.messages.other.fullname}</h5>
            <ScrollToBottom className="chatContainer">
              <Button variant="secondary" className="loadMore" onClick={this.loadOlder}>
                Last inn eldre meldinger
              </Button>
              {this.state.messages.messages.map((item) => (
                   <div className={`message ${item.isSender == true ? 'sender' : 'received'}`}>
                     <div className="messagePicture">
                       <img style={{height: "30px", width: "30px", borderRadius: "15px", marginRight: "50px", marginLeft: "10px"}} src={`https://l16.vixo.no/api/public/get/image/${item.profilepicture}`} />
                     </div>
                     <div className="messageContent" dangerouslySetInnerHTML={{__html: item.message}}>
                     </div>

                     <div className="spacer"></div>
                   </div>
                 )
               )}
            </ScrollToBottom>
            <div className="TextAreaWrapper">

              <div className="textAreaContainer">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control as="textarea" rows="3" value={this.state.message} onChange={(e) => {this.setState({message: e.target.value})}} />
                </Form.Group>
              </div>

              <div className="sendBtn">
                <Button variant="primary" size="lg" block className="sendBtn" onClick={this.sendMessage}>
                  Send
                </Button>
              </div>

            </div>
          </Card.Body>
        </Card>
      </div>

      </div>
    );
  }
}
