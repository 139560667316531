import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import Home from './pages/home/home.jsx';
import Position from './pages/position/position.jsx';
import Profile from './pages/profile/profile.jsx';
import Login from './pages/login/login.jsx';
import Messages from './pages/messages/messages.jsx';

import Membership from './pages/membership/membership.jsx';
import Companies from './pages/companies/companies.jsx';
import Company from './pages/companies/company.jsx';

import RecruiterPositions from './pages/recruiter/positions/positions.jsx';
import RecruiterPosition from './pages/recruiter/position/position.jsx';
import RecruiterCreatePosition from './pages/recruiter/createposition/createposition.jsx';

import logo from "./images/logo.png"

import userMenu from "./menus/user/menu.jsx";

class App extends React.Component {

  state = {
    userdata: {}
  }

  constructor(props) {
    super(props);

    this.menuRender = this.menuRender.bind(this);

    axios.defaults.headers.common["token"] = localStorage.getItem("token");
    console.log("Should work");
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      this.setState({isLoggedIn: false});
    } else {
      this.setState({isLoggedIn: true});
      this.loadUserData();
    }
  }


  loadUserData() {
    axios.get("https://l16.vixo.no/api/public/get/user").then(res => {
      let data = res.data;
      this.setState({userdata: res.data});
    })
  }

  hideIfNotLoggedin() {
    if(this.state.isLoggedIn == true) {
      return "";
    } else {
      return "hidden";
    }
  }

  menuRender() {
    if(this.state.userdata.role == "user") {
      return (
        <userMenu />
      );
    } else {
      return (
        <div>
          {this.state.userdata.role}
        </div>
      );
    }
  }

  render() {

    return (
      <Router>
        <div className="App">
          <div class="container full">
            <div className="navigation">
              <div className="logo">
                <Link to="/">
                    <img
                      alt=""
                      src={logo}
                      height="40px"
                      className="d-inline-block align-top"
                    />{' '}
                </Link>
              </div>

              <div className="menu">

                <div className={`${this.state.userdata.role == 'user' ? '':'hidden'}`} style={{display: "inline"}}>
                  <Link to="/">Utlysninger</Link>
                  <Link to="/membership">Medlemsskap</Link>
                </div>

                <div className={`${this.state.userdata.role == 'recruiter' ? '':'hidden'}`} style={{display: "inline"}}>
                  <Link to="/recruiter/positions">Dine utlysninger</Link>
                </div>

                <Link to="/messages">Meldinger</Link>

              </div>

              <div className="login">
                <Link to={`/profile/${this.state.userdata.url}`}>{this.state.userdata.firstname} {this.state.userdata.surname}
                  <img style={{height: "40px", width: "40px", borderRadius: "40px", marginRight: "50px", marginLeft: "10px"}} src={`https://l16.vixo.no/api/public/get/image/${this.state.userdata.profilepicture}`} />
                </Link>
            </div>
            </div>
          </div>

          <Switch>
            <Route path="/explore">
              Explore
            </Route>
            <Route path="/rising-stars">
              Rising tutors
            </Route>
            <Route path="/login" component={Login}></Route>
            <Route path="/position/:id" component={Position}></Route>
            <Route path="/profile/:url" exact component={Profile}></Route>
            <Route path="/profile/:url/application/:application" exact component={Profile}></Route>

            <Route path="/messages/:id" component={Messages}></Route>
            <Route path="/messages" component={Messages}></Route>

            <Route path="/membership" component={Membership}></Route>
            <Route path="/companies" component={Companies}></Route>
            <Route path="/company/:url" component={Company}></Route>

            <Route path="/recruiter/create/position" component={RecruiterCreatePosition}></Route>
            <Route path="/recruiter/positions" component={RecruiterPositions}></Route>
            <Route path="/recruiter/position/:id" component={RecruiterPosition}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>

        </div>
      </Router>
    );
  }
}

export default App;
